@import "@travellocal/ui/dist/import";

// https://stackoverflow.com/a/38908284/268555

html {
  width: 100%;
  height: 100%;
}

label {
  --label-weight: 400;
}

body {
  width: 100%;
  overflow-x: clip;
}

html,
body {
  margin: 0;
  padding: 0;
}

// Styles shared between collection and blog pages
.content-page {
  &__container {
    @include mobile {
      padding: 0;
    }

    @include tablet {
      padding-left: var(--space-3);
      padding-right: var(--space-3);
    }
  }
}

.html-render-block {
  h2 {
    font-family: var(--title-family);
    font-size: var(--size-3);
  }
}

// TODO move this into UI lib
.p-0-touch {
  @include touch {
    padding: 0 !important;
  }
}

// https://app.clickup.com/t/18339656/PE-2469
// safari on iphone takes the full calculated width of all items within react-slick-slider
.overflow-iphone-fix {
  @include mobile {
    overflow: hidden;
  }
}

// disable strong-to-h2 unless is an old article
.tl-article:not(.tl-article__is-old) .alloy-html > p > b:only-child,
.tl-article:not(.tl-article__is-old) .alloy-html > p > strong:only-child {
  font-family: var(--body-family);
  font-weight: 600;
  font-size: 16px;
  color: var(--colour-navy);
  line-height: 1.6;
}
